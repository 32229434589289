(function () {
  'use strict';

  angular
    .module('bioscann')
    .config(config);

  function config($urlRouterProvider, $httpProvider, $locationProvider, $mdThemingProvider, $provide, $rootScopeProvider, $windowProvider, $documentProvider) {
    let $window = $windowProvider.$get(),
        $document = $documentProvider.$get[1]($window)[0];
    $window.isIE = () => {
      const ua = $window.navigator.userAgent,
          msie = ua.indexOf('MSIE '),
          trident = ua.indexOf('Trident/');

      return msie > 0 || trident > 0;
    };
    $rootScopeProvider.digestTtl(80);
    // treeConfig.defaultCollapsed = true;
    $provide.decorator('taOptions', ['taRegisterTool', '$delegate', 'ImageUpload', function (taRegisterTool, taOptions, ImageUpload) {
      taRegisterTool('image', {
        iconclass: 'fa fa-picture-o',
        tooltiptext: 'insert image',
        action: function (promise, restoreSelection) {
          var that = this;
          ImageUpload.show(null, function (html) {
            if (html) {
              restoreSelection();
              promise.resolve();
              return that.$editor().wrapSelection('insertHtml', html);
            }
          });
          return false;
        }
      });
      taRegisterTool('fontColor', {
        display: '<md-icon colorpicker ng-model="color" class="btn-color">format_color_text</md-icon>',
        action: function () {
          let self = this;
          self.$watch('color', function (newValue) {
            self.$editor().wrapSelection('foreColor', newValue);
          });
          return false;
        }
      });
      taRegisterTool('fontName', {
        display: `<md-menu class="l--p-a-0"><md-button aria-label="Open phone interactions menu" class="md-icon-button l--p-a-0 btn-fonts" ng-click="$mdMenu.open($event)"> <md-icon md-menu-origin>font_download</md-icon> </md-button> <md-menu-content width="4"> <md-menu-item ng-repeat="o in options"> <md-button ng-click="action($event, o.css)" style='font-family: {{o.css}}'> {{o.name}} </md-button> </md-menu-item> </md-menu-content></md-menu>`,
        action: function (event, font) {
          event.stopPropagation();
          return this.$editor().wrapSelection('fontName', font);
        },
        options: [
          {name: 'Default', css: `'Lato', sans-serif`},
          {name: 'Gloria Hallelujah', css: `'Gloria Hallelujah', cursive`},
          {name: 'Cedarville Cursive', css: `'Cedarville Cursive', cursive`},
          {name: 'Rock Salt', css: `'Rock Salt', cursive`},
          {name: 'Swanky and Moo Moo', css: `'Swanky and Moo Moo', cursive`}
        ]
      });
      taRegisterTool('fontSize', {
        display: `<md-menu class="l--p-a-0"><md-button aria-label="Open phone interactions menu" class="md-icon-button l--p-a-0 btn-fonts" ng-click="$mdMenu.open($event)"> <md-icon md-menu-origin>format_size</md-icon> </md-button> <md-menu-content width="4"> <md-menu-item ng-repeat="o in options"> <md-button ng-click="action($event, o.value)" style='font-size: {{o.css}}'> {{o.name}} </md-button> </md-menu-item> </md-menu-content></md-menu>`,
        action: function (event, font) {
          event.stopPropagation();
          return this.$editor().wrapSelection('fontSize', parseInt(font, 10));
        },
        options: [
          {name: 'xx-small', css: 'xx-small', value: 1},
          {name: 'x-small', css: 'x-small', value: 2},
          {name: 'small', css: 'small', value: 3},
          {name: 'medium', css: 'medium', value: 4},
          {name: 'large', css: 'large', value: 5},
          {name: 'x-large', css: 'x-large', value: 6},
          {name: 'xx-large', css: 'xx-large', value: 7}
        ]
      });
      taOptions.toolbar = [
        [],
        ['bold', 'italics', 'underline', 'strikeThrough', 'ul', 'ol', 'redo', 'undo', 'clear'],
        ['justifyLeft', 'justifyCenter', 'justifyRight', 'indent', 'outdent'],
        ['html', 'image', 'fontColor', 'fontName', 'fontSize', 'insertLink', 'insertVideo']
      ];
      return taOptions;
    }]);
    $mdThemingProvider.definePalette('bioscannDarkBlue', {
      50: '#507DD0',
      100: '#ebf5ff',
      200: '#b4dbfe',
      300: '#6ebafc',
      400: '#50abfc',
      500: '#329dfb',
      600: '#148ffa',
      700: '#057feb',
      800: '#046fcd',
      900: '#035faf',
      A100: '#ffffff',
      A200: '#ebf5ff',
      A400: '#50abfc',
      A700: '#057feb',
      contrastDefaultColor: 'light',
      contrastDarkColors: '50 100 200 300 400 500 A100 A200 A400'
    });
    $mdThemingProvider.definePalette('bioscannBlue', {
      50: '#ffffff',
      100: '#cdf1f3',
      200: '#a0e4e7',
      300: '#67d3d9',
      400: '#4eccd3',
      500: '#36c5cd',
      600: '#2eb0b7',
      700: '#27989e',
      800: '#218186',
      900: '#1b696d',
      A100: '#ffffff',
      A200: '#cdf1f3',
      A400: '#4eccd3',
      A700: '#27989e',
      contrastDefaultColor: 'light',
      contrastDarkColors: '50 100 200 A100 A200'
    });
    $mdThemingProvider.definePalette('bioscannPurple', {
      50: '#d7d2e7',
      100: '#aba0cd',
      200: '#8a7bb9',
      300: '#64529b',
      400: '#574887',
      500: '#4a3d73',
      600: '#3d325f',
      700: '#30284b',
      800: '#231d37',
      900: '#171323',
      A100: '#d7d2e7',
      A200: '#aba0cd',
      A400: '#574887',
      A700: '#30284b',
      contrastDefaultColor: 'light',
      contrastDarkColors: '50 100 200 A100 A200'
    });

    $mdThemingProvider.theme('default')
      .primaryPalette('bioscannPurple')
      .accentPalette('grey');

    $mdThemingProvider.theme('bioscannBlueTheme')
      .primaryPalette('bioscannBlue');

    $mdThemingProvider.theme('bioscannDarkBlueTheme')
      .primaryPalette('bioscannDarkBlue');

    $locationProvider.html5Mode(true);
    $urlRouterProvider.otherwise('/');
    // Prototype to covert image file to base64
    File.prototype.convertToBase64 = function (callback) {
      var FR = new FileReader();
      FR.onload = function (e) {
        callback(e.target.result);
      };
      FR.readAsDataURL(this);
    };
    if (!('path' in $window.Event.prototype)) {
      Object.defineProperty($window.Event.prototype, 'path', {
        get: function () {
          let path = [],
              currentElem = this.target;
          while (currentElem) {
            path.push(currentElem);
            currentElem = currentElem.parentElement;
          }
          if (path.indexOf($window) === -1 && path.indexOf($document) === -1) {
            path.push($document);
          }

          if (path.indexOf($window) === -1) {
            path.push($window);
          }
          return path;
        }
      });
    }
    $httpProvider.interceptors.push(['$q', '$injector',
      ($q, $injector) => {
        return {
          request: (conf) => {
            if (conf.method === 'POST' || conf.method === 'PUT' || conf.method === 'DELETE' || conf.method === 'GET') {
              const user = $injector.get('User').getUserCookie();
              if (angular.isDefined(user) && angular.isDefined(user.user)) {
                conf.headers.Authorization = `Bearer ${user.user.token}`;
              }
            }
            return conf;
          },

          responseError: function (rejection) {
            const $mdToast = $injector.get('$mdToast');
            if (rejection.status === 400 && rejection.data.error && rejection.data.error[0] && rejection.data.error[0].length > 1) {
              $mdToast.show(
                $mdToast.simple()
                  .textContent(rejection.data.error[0][1])
                  .position('bottom right')
                  .hideDelay(3000)
              );
            }
            if (rejection.status === 500 || rejection.status === -1) {
              $mdToast.show(
                $mdToast.simple()
                  .textContent('Something has gone wrong. Please retry. If the problem persists, contact your administrator.')
                  .position('bottom right')
                  .hideDelay(3000)
              );
            }
            return $q.reject(rejection);
          }
        };
      }]);
  }
}());
